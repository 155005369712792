<template>
  <centered-column-layout class="h-screen p-5">
    <!-- FORM -->
    <template v-if="form !== null">
      <div class="w-full flex flex-col md:flex-row justify-between mb-5">
        <a-button
          class="order-2 self-end mt-2 md:order-1 md:self-center md:mt-0"
          @click="$router.push('/')"
        >
          {{ gotoCabinetButtonTitle }}
        </a-button>

        <div
          v-if="form && form.user"
          class="flex justify-end md:order-2"
        >
          <user-main-info
            direction="inline-reverse"
            :avatar="form.user.avatar"
            :login="form.user.login"
            :name="form.user.name"
          />
        </div>
      </div>

      <div
        class="w-full"
        v-if="form.state === 'Availible'"
      >
        <a-spin :spinning="formLoading">
          <Form
            class="mt-5"
            :form="form"
            :on-send="send"
            key="0"
          />
        </a-spin>
      </div>

      <div
        v-else
        class="w-full text-center"
        key="1"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <!-- STATE -->
          <a-card
            v-if="!showTimer"
            key="3"
            :class="`form-state_${snakeCase(form.state).toLowerCase()}`"
          >
            <b class="form-state__response-text text-xl">{{ $t(`form_state_${snakeCase(form.state).toLowerCase()}`) }}</b>
          </a-card>
          <!-- COUNTDOWN -->
          <a-card
            v-else
            key="4"
          >
            <a-statistic-countdown
              class="text-center"
              :title="$t('form_item_redirect_in')"
              :value="new Date().getTime() + 5000"
              format="s"
              @finish="onCountdownFinish"
            />
            <!--              <div-->
            <!--                class="flex flex-col flex-wrap items-center justify-center"-->
            <!--                slot="process"-->
            <!--                slot-scope="{timeObj}"-->
            <!--              >-->
            <!--                <h6>{{ $t("form_item_redirect_in") | capitalizeFirstLetter }}:</h6>-->
            <!--                <p class="mt-4 text-xl"><b>{{ timeObj.ceil.s }}</b></p>-->
            <!--              </div>-->
            <!--              <div slot="finish"><h6>{{ $t("form_item_redirect") | capitalizeFirstLetter }}</h6></div>-->
            <!--            </a-statistic-countdown>-->

            <!--                <h6>{{ $t('form_item_go_back') }}</h6>-->
          </a-card>
        </transition>
      </div>
    </template>
  </centered-column-layout>
</template>

<script>
import Form from "@/components/questionnaire/Form";
import Questionnaire from "@/includes/logic/Questionnaire";
import CenteredColumnLayout from "@/components/CenteredColumnLayout";
import { errorNotification } from "@/includes/NotificationService";

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo'

import { snakeCase } from "lodash";

export default {
  name: "form",
  components: {
    CenteredColumnLayout,
    Form,
    UserMainInfo,
  },
  data() {
    return {
      form: null,
      showTimer: false,
      formLoading: false
    };
  },
  computed: {
    gotoCabinetButtonTitle() {
      if (navigator.language.includes('ru')) {
        return 'В кабинет'
      } else {
        return 'Cabinet'
      }
    }
  },
  methods: {
    snakeCase,
    onCountdownFinish() {
      setTimeout(() => {
        if (this.form.return_url) {
          window.location.href = this.form.return_url
        } else {
          window.close();
        }
      }, 1000);
    },
    send() {
      this.formLoading = true

      Questionnaire.submitForm(this.form, this.$route.params.key)
        .then(res => {
          this.form = res.data;
          setTimeout(() => {
            this.showTimer = true;
          }, 1000);
        })
        .catch(errorNotification)
        .finally(() => {
          this.formLoading = false
        });
    },
    getForm() {
      return Questionnaire.runForm("open", this.$route.params.key);
    },
  },
  mounted() {
    this.$baseTemplate.saveButton.hide()
    this.$baseTemplate.loader.open()

    this.getForm()
      .then(res => {
        if (res.data.state === "Availible") {
          this.form = Questionnaire.convertFormFromServer(res.data);
        } else {
          this.form = res.data;
        }
      })
      .catch((error) => {
        errorNotification(error)

        if (error.response?.status !== 401) {
          this.$router.replace({ name: 'login' })
        }
      })
      .finally(() => {
        this.$baseTemplate.loader.close()
      });
  },
};
</script>

<style lang="scss">
.form-state_moderation {
  .form-state__response-text {
    color: rgba(var(--a-warning), 1);
  }
}

.form-state_accepted {
  .form-state__response-text {
    color: rgba(var(--a-success), 1)
  }
}

.form-state_rejected {
  .form-state__response-text {
    color: rgba(var(--a-danger), 1)
  }
}

.form-state_not_handled {
  .form-state__response-text {
    color: rgba(var(--a-primary), 1)
  }
}

.form-state_filled {
  .form-state__response-text {
    color: rgba(var(--a-primary), 1)
  }
}

.form-state_availible {
  .form-state__response-text {
    color: rgba(var(--a-primary), 1)
  }
}
</style>
